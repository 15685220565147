<template>
  <div class="coupon_wrapper">
    <el-tabs type="card" v-model="tabName" @click @tab-click="handleClick">
      <el-tab-pane label="卡券活动" name="1">
        <el-form :model="CardDetail" :rules="cardActivityRules" label-width="130px">
          <el-form-item label="卡券活动名称" prop="name">
            <el-input
              v-model="CardDetail.name"
              placeholder="请输入卡券活动名称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="活动介绍图" required>
            <label for="brand-logo" class="el-upload el-upload--picture-card">
              <input
                type="file"
                ref="brandlogo"
                hidden
                id="brand-logo"
                data-type="brandLogo"
                name="upload"
                accept="image/*"
                @change="uploadImage"
              />
              <img
                v-if="CardDetail.introduceImg"
                :src="CardDetail.introduceImg"
                style="width:142px;height:142px;"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </label>
            <span class="ml10 form_tips">建议尺寸280 * 230px，支持JPG，PNG，JPEG格式</span>
          </el-form-item>-->
          <el-form-item label="购买价" prop="seckillPrice">
            <el-input
              v-model="CardDetail.seckillPrice"
              placeholder="请输入购买价"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="虚拟抢购量">
            <el-input
              v-model.number="CardDetail.virtualSales"
              placeholder="请输入虚拟抢购量"
              maxlength="10"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="活动最高减免" prop="">
            <el-input
              v-model="CardDetail.maxAmount"
              placeholder="请输入最高减免"
              maxlength="5"
              show-word-limit
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="购卡须知" prop="name">
            <el-input
              v-model="CardDetail.prompt"
              placeholder="请输入最高减免"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>-->
          <el-form-item label="购卡须知">
            <vue-ueditor-wrap v-model="CardDetail.prompt" :config="ueditorConfig"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitCouponCard">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="优惠券列表" name="2">
        <div class="select_wrap">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="ToSaveOrEditCoupon(0)"
          >添加优惠券</el-button>
          <div>
            <span>请输入优惠券名称：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入优惠券名称"
              v-model="CouponInfo.name"
            ></el-input>
          </div>
          <div>
            <span>品牌商：</span>
            <el-select v-model="CouponInfo.brandId" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option
                v-for="item in BrandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
        </div>
        <el-table
          class="coupon_table"
          ref="multipleTable"
          :data="CouponList"
          border
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="60"></el-table-column>
          <el-table-column prop="id" label="优惠券ID"></el-table-column>
          <el-table-column prop="name" label="优惠券名称"></el-table-column>
          <el-table-column prop="brandName" label="所属品牌"></el-table-column>
          <el-table-column label="减免方式" width="160px">
            <template slot-scope="scope">
              <!-- <el-tag type="primary" @click>点击查看减免方式</el-tag> -->
              <el-link
                type="primary"
                :underline="false"
                @click="ToCouponDetail(scope.row)"
              >点击查看减免方式</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="maxAmount" label="最高减免"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.status=='forbidden'">禁用</el-tag>
              <el-tag type="success" v-if="scope.row.status=='active'">正常</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" fixed="right" width="220px">
            <template slot-scope="scope">
              <el-tag
                type="warning"
                v-if="scope.row.status=='active'"
                @click="ConfirmSetOffline(scope.row.id)"
              >禁用</el-tag>
              <el-tag
                type="success"
                v-if="scope.row.status=='forbidden'"
                @click="ConfirmSetOnline(scope.row.id)"
              >启用</el-tag>
              <el-tag type="primary" class="ml10 mr10" @click="ToSaveOrEditCoupon(1,scope.row)">编辑</el-tag>
              <el-tag
                type="danger"
                v-if="scope.row.status=='forbidden'"
                @click="ConfirmDelCoupon(scope.row.id)"
              >删除</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="tab-page">
          <div>
            <el-button type="primary" @click="handleCheckAllChange">全 选</el-button>
            <el-button type="primary" @click="DelCoupon">批量删除</el-button>
            <el-button type="primary" @click="SetOnline">批量启用</el-button>
            <el-button type="primary" @click="SetOffline">批量禁用</el-button>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="CouponInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="CouponTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加优惠券 -->
    <el-dialog :visible.sync="addCouponDrawer" center width="600px" class="secondsKill_drawer">
      <p class="view_title">{{editCouponTitle}}</p>
      <el-form :model="editCouponForm" :rules="addCouponRules" label-width="100px">
        <el-form-item label="优惠券名称" prop="name">
          <el-input
            v-model="editCouponForm.name"
            placeholder="请输入优惠券名称"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="所属品牌商" required>
          <el-select v-model="editCouponForm.brandId" placeholder="请选择">
            <el-option v-for="item in BrandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="减免方式" required>
          <el-radio-group v-model="editCouponForm.preferenceType">
            <el-radio :label="1">常规满减</el-radio>
            <el-radio :label="2">自定义满减文案</el-radio>
          </el-radio-group>
         <template v-if="editCouponForm.preferenceType === 1">
           <ul class="fee-ul">
             <li class="disflex fvc mb10" v-for="(item,ii) in editCouponForm.rule" :key="ii">
               <el-input v-model="item[0]" maxlength="6" placeholder="请输入销售价" class="mr10" style="width:160px; margin-left: -52px;">
                 <template slot="prepend">满</template>
               </el-input>
               <el-input v-model="item[1]" maxlength="5" placeholder="请输入销售价" class="mr10" style="width:160px">
                 <template slot="prepend">减</template>
               </el-input>
               <el-tag class="el-icon-circle-close c-p" type="danger" @click="delCouponeFee(item,ii)">删除</el-tag>
             </li>
           </ul>
           <el-tag class="el-icon-circle-plus-outline mt10 c-p" type="primary" @click="addCouponFee">添加</el-tag>
         </template>
          <template v-else>
            <el-input
              type="textarea"
              :rows="3"
              maxlength="200"
              show-word-limit
              placeholder="请输入自定义满减文案"
              v-model="editCouponForm.preferenceContent">
            </el-input>
          </template>
        </el-form-item>
        <el-form-item label="最高减免" prop="">
          <el-input v-model="editCouponForm.maxAmount" placeholder="请输入最高减免" maxlength="5"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model.number="editCouponForm.sort" placeholder="请输入排序" maxlength="3"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="name">
          <el-radio-group v-model="editCouponForm.status">
            <el-radio label="active">启用</el-radio>
            <el-radio label="forbidden">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitCoupon">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加商品 -->
    <el-dialog :visible.sync="showCouponDetail" center width="300px" class="secondsKill_drawer">
      <!-- <p class="view_title">减免方式</p> -->
      <el-table v-if="CouponDetail.preferenceType === 1" class="coupon_table" :data="CouponDetail.rule" border stripe style="width: 100%">
        <el-table-column prop="status" label="减免方式">
          <template slot-scope="scope">
            <div>满{{scope.row[0]}}减{{scope.row[1]}}</div>
          </template>
        </el-table-column>
      </el-table>
      <div v-else>
        {{ CouponDetail.preferenceContent }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MarketingAjax from "@/utils/https/modules/Marketing.request.js";
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

import filterXSS from "filterXSS";
import VueUeditorWrap from "vue-ueditor-wrap";

let origin = window.location.origin;
let ueditorurl = "";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192.168.") ||
  window.location.hostname.includes("admin-test.youxiangzb.com")
) {
  ueditorurl = "/UEditor/";
} else {
  // ueditorurl = "https://admin.youxiangzb.com/admin/open/UEditor/";
  ueditorurl = "/UEditor/";
}
export default {
  name: "Coupon", // 活动-营销-卡券设置
  components: {
    VueUeditorWrap,
  },
  props: {
    activityID: String,
  },
  data() {
    return {
      ueditorConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        initialFrameWidth: 700,
        initialFrameHeight: 320,
        // UEDITOR_HOME_URL: `${process.env.VUE_APP_URL}/js/ueditor1_4_3_3-utf8-net/utf8-net/`
        UEDITOR_HOME_URL: ueditorurl,
      },
      couponInput: "",
      selectValue: "",
      tabName: "1",
      pageIndex: 1, // 当前页码
      pageSize: 10, // 请求单前页码总条数
      secondsKillTotal: 0, // 总条数
      addCouponDrawer: false,
      addCouponForm: {
        name: "",
        radio: 0,
      },
      addCouponRules: {
        name: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
        ],
        maxAmount: [
          { required: true, message: "请输入最高减免", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      cardActivityForm: {
        name: "",
        poster: "",
        value: "",
      },
      cardActivityRules: {
        seckillPrice: [
          { required: true, message: "请输入购买价", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入卡券活动名称", trigger: "blur" },
        ],
        virtualSales: [
          { required: true, message: "请输入虚拟抢购量", trigger: "blur" },
        ],
        maxAmount: [
          { required: true, message: "请输入最高减免", trigger: "blur" },
        ],
      },

      // 卡券设置用参数
      CouponList: [],
      CouponTotal: 0,
      CouponInfo: {
        actId: 0,
        brandId: "",
        name: "",
        page: 1,
        size: 10,
      },
      multipleSelection: [],
      BrandList: [],
      CouponDetail: [],
      showCouponDetail: false,
      CardDetail: {
        actId: this.activityID,
        name: "",
        introduceImg: "https://j.weizan.cn/youxiang/coupon-detail-bg.png",
        maxAmount: 0,
        prompt: "  ",
        seckillPrice: 0,
        virtualSales: 0,
      },
      editCouponForm: {
        // actId: 0,提交时赋值
        // id: 0,编辑点开时赋值
        preferenceType: 1,
        brandId: "",
        maxAmount: "",
        name: "",
        rule: [[0, 0]],
        sort: 10,
        status: "active",
      },
      editCouponTitle: "添加优惠券",
      IsopenCouponCard: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleCheckAllChange() {
      this.$refs.multipleTable.toggleAllSelection();
    },
    //切换tab方法
    handleClick() {
      if (this.tabName == "1") {
        this.getCouponCard();
      } else if (this.tabName == "2") {
        this.CouponInfo.page = 1;
        this.getCouponList();
      }
    },
    // 上传
    async uploadImage(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4,
        });
        this.CardDetail.introduceImg = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 表格 页码请求
    handleCurrentChange(val) {
      this.CouponInfo.page = val;
      this.getCouponList();
    },
    searchPage() {
      this.CouponInfo.page = 1;
      this.getCouponList();
    },
    delConfirm() {
      this.$message.success("删除成功");
    },
    // 优惠券列表
    async getCouponList() {
      try {
        this.CouponInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await MarketingAjax.getCouponList(this.CouponInfo);
        this.CouponList = list;
        this.CouponTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 启用优惠券确认
    ConfirmSetOnline(Id) {
      var self = this;
      this.$confirm("确认启用优惠券？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.setCouponOnline(Id);
        })
        .catch(() => {});
    },
    async setCouponOnline(Id) {
      await MarketingAjax.setCouponOnline(Id);
      await this.getCouponList();
      this.$message.success("启用优惠券成功");
    },
    // 禁用优惠券确认
    ConfirmSetOffline(Id) {
      var self = this;
      this.$confirm("禁用后将不再显示在微信端，确认禁用优惠券吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setCouponOffline(Id);
        })
        .catch(() => {});
    },
    async setCouponOffline(Id) {
      await MarketingAjax.setCouponOffline(Id);
      await this.getCouponList();
      this.$message.success("禁用优惠券成功");
    },
    // 删除优惠券确认
    ConfirmDelCoupon(Id) {
      var self = this;
      this.$confirm("优惠券删除后将不可恢复，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setCouponDel(Id);
        })
        .catch(() => {});
    },
    async setCouponDel(Id) {
      await MarketingAjax.setCouponDel(Id);
      await this.getCouponList();
      this.$message.success("删除优惠券成功");
    },
    // 优惠券列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量启用优惠券
    SetOnline() {
      var self = this;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm("确认启用已选优惠券？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection.length; i++) {
            var item = self.multipleSelection[i];
            if (item && item.status == "forbidden") {
              ids.push(item.id);
            }
          }
          if (ids.length > 0) {
            var query = ids.toString();
            this.setCouponOnline(query);
          } else {
            this.$message.warning("请选择已禁用的优惠券");
          }
        })
        .catch(() => {});
    },
    // 批量下架商品
    SetOffline() {
      var self = this;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm(
        "禁用后将不再显示在微信端，确认禁用已选优惠券吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection.length; i++) {
            var item = self.multipleSelection[i];
            if (item && item.status == "active") {
              ids.push(item.id);
            }
          }
          if (ids.length > 0) {
            var query = ids.toString();
            this.setCouponOffline(query);
          } else {
            this.$message.warning("请选择已启用的优惠券");
          }
        })
        .catch(() => {});
    },
    // 批量删除优惠券
    DelCoupon() {
      var self = this;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请先选择"); // 弹出提示
        return;
      }
      this.$confirm("删除后将不可恢复，确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = [];
          for (var i = 0; i < self.multipleSelection.length; i++) {
            var item = self.multipleSelection[i];
            if (item && item.status == "forbidden") {
              ids.push(item.id);
            }
            if (item.status == "active") {
              this.$message.warning(
                "选择的商品中含有已启用的优惠券，请先禁用后再删除"
              );
              return;
            }
          }
          var query = ids.toString();
          this.setCouponDel(query);
        })
        .catch(() => {});
    },
    // 查询品牌商列表
    async getActivityBrandList() {
      try {
        const {
          data: { list },
        } = await ActivityAjax.getActivityBrandList({
          actId: this.activityID,
          catId: "",
          name: "",
          page: 1,
          size: 1000,
        });
        this.BrandList = list;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查看优惠券满减详情
    ToCouponDetail(row) {
      this.CouponDetail = row;
      this.showCouponDetail = true;
    },
    // 查看卡券信息，传入活动id。(尚未创建时返回null）
    async getCouponCard() {
      try {
        const data = await MarketingAjax.getCouponCard(this.activityID);
        console.log(data);
        if (data.data) {
          this.CardDetail = data.data;
          this.IsopenCouponCard = true;
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 确认新建或编辑卡券信息
    async submitCouponCard() {
      try {
        this.CardDetail.actId = this.activityID;
        let act = JSON.parse(JSON.stringify(this.CardDetail));
        if (this.isNullOrEmpty(act.name)) {
          this.$message.warning("卡券活动名称不能为空");
          return;
        }
        // if (!act.introduceImg) {
        //   this.$message.warning("活动介绍图不能为空");
        //   return;
        // }
        if (this.isNullOrEmpty(act.seckillPrice)) {
          this.$message.warning("购买价不能为空");
          return;
        }
        if (act.seckillPrice <= 0) {
          this.$message.warning("购买价不能小于0");
          return;
        }
        if (/^\d+(\.\d{1,2})?$/.test(act.seckillPrice) == false) {
          this.$message.warning("请输入正确购买价，最多两位小数");
          return;
        }
        if (act.seckillPrice > 99999999 || act.seckillPrice < 0) {
          this.$message.warning("购买价范围为0~99999999");
          return;
        }

        if (act.virtualSale && !/(^[1-9]\d*$)/.test(act.virtualSales)) {
          if (act.virtualSales == 0) {
          } else {
            this.$message.warning("虚拟抢购量需为整数且大于等于0");
            return;
          }
          if (act.virtualSales > 99999999 || act.virtualSales < 0) {
            this.$message.warning("虚拟抢购量范围为0~99999999");
            return;
          }
        }
        // if (this.isNullOrEmpty(act.maxAmount)) {
        //   this.$message.warning("最高减免不能为空");
        //   return;
        // }
        if (act.maxAmount) {
          if (/^\d+(\.\d{1,2})?$/.test(act.maxAmount) == false) {
            if (act.maxAmount == 0) {
            } else {
              this.$message.warning("最高减免需为整数且大于等于0");
              return;
            }
          }
          if (act.maxAmount > 99999 || act.maxAmount < 0) {
            this.$message.warning("最高减免范围为0~99999");
            return;
          }
        }

        console.log(act);
        await MarketingAjax.saveCouponCard(act);
        this.$message.success("编辑卡券信息成功");
        await this.getCouponCard();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 进入新增/编辑优惠券
    async ToSaveOrEditCoupon(type, row) {
      console.log(type, row);
      if (!this.IsopenCouponCard) {
        this.$message.warning(
          "卡券未创建，请前往【卡券设置】-【卡券活动】配置卡券信息"
        );
        return;
      }
      this.editCouponForm = {
        // actId: 0,提交时赋值
        // id: 0,编辑点开时赋值
        preferenceType: 1,
        brandId: "",
        maxAmount: "",
        name: "",
        rule: [[0, 0]],
        sort: 1,
        status: "active",
      };
      if (type == 1) {
        this.editCouponForm = row;
        this.editCouponTitle = "编辑优惠券";
      }
      this.addCouponDrawer = true;
    },
    // 新增/编辑优惠券，不传id时表示新增，新增成功后会返回优惠券id。添加优惠券前必须先建卡券（否则会抛异常）
    async submitCoupon() {
      try {
        var self = this;
        this.editCouponForm.actId = this.activityID;
        let act = JSON.parse(JSON.stringify(this.editCouponForm));
        // 判断当前减免方式
        if (act.preferenceType === 1) {
          delete act.preferenceContent
        } else {
          delete act.rule
        }
        if (this.isNullOrEmpty(act.name)) {
          this.$message.warning("优惠券名称不能为空");
          return;
        }
        // if (!act.brandId) {
        //   this.$message.warning("请选择品牌商");
        //   return;
        // }
        // if (this.isNullOrEmpty(act.maxAmount)) {
        //   this.$message.warning("最高减免不能为空");
        //   return;
        // }
        // if (act.maxAmount <= 0) {
        //   this.$message.warning("最高减免不能小于0");
        //   return;
        // }
        // if (/^\d+(\.\d{1,2})?$/.test(act.maxAmount) == false) {
        //   this.$message.warning("请输入正确最高减免，最多两位小数");
        //   return;
        // }
        if (this.isNullOrEmpty(act.sort)) {
          this.$message.warning("排序值不能为空");
          return;
        }
        if (!/(^[1-9]\d*$)/.test(act.sort)) {
          this.$message.warning("排序值需为正整数");
          return;
        }
        if (act.sort > 999 || act.sort < 0) {
          this.$message.warning("排序值为1-999");
          return;
        }
        if (act.preferenceType === 2 && !act.preferenceContent) {
          this.$message.warning("请输入自定义满减文案!");
          return;
        }
        if (act.preferenceType === 1) {
          for (var i = 0; i < self.editCouponForm.rule.length; i++) {
            var item = self.editCouponForm.rule[i];
            if (self.isNullOrEmpty(item[0]) || self.isNullOrEmpty(item[1])) {
              self.$message("请输入满减配置！");
              return;
            }
            if (/^\d+(\.\d{1,2})?$/.test(item[0]) == false) {
              self.$message("请输入正确门槛金额，最多两位小数");
              return;
            }
            if (item[0] > 100000) {
              self.$message("门槛金额最大值为100000！");
              return;
            }
            if (item[0] < 0.01) {
              self.$message("门槛金额不能小于0.01元");
              return;
            }
            if (/^\d+(\.\d{1,2})?$/.test(item[1]) == false) {
              self.$message("请输入正确优惠金额，最多两位小数");
              return;
            }
            if (item[1] > 99999) {
              self.$message("优惠金额最大值为99999！");
              return;
            }
            if (item[1] < 0.01) {
              self.$message("优惠金额不能小于0.01元");
              return;
            }
            if (Number(item[0]) < Number(item[1])) {
              self.$message("优惠金额不可大于门槛金额");
              return;
            }
          }
        }

        console.log(act);
        await MarketingAjax.saveCoupon(act);
        if (this.editCouponForm.id) {
          this.$message.success("编辑优惠券成功");
        } else {
          this.$message.success("新建优惠券成功");
        }
        await this.getCouponList();
        this.addCouponDrawer = false;
      } catch (error) {
        if (error == "卡券未创建") {
          error = "卡券未创建，请前往【卡券设置】-【卡券活动】配置卡券信息";
        }
        this.$message.error(error);
      }
    },
    addCouponFee() {
      //添加优惠券满减费用配置
      var item = [0, 0];
      this.editCouponForm.rule.push(item);
    },
    delCouponeFee(item, index) {
      //删除优惠券满减费用配置
      var self = this;
      self.editCouponForm.rule.splice(index, 1);
    },
  },
  created() {
    // this.getCouponList();
    this.getActivityBrandList();
    this.getCouponCard();
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image",
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.coupon_wrapper {
  .el-tabs__content {
    .select_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      div {
        height: 40px;
        line-height: 40px;
        margin: 0 10px;
        .el-input {
          width: 200px;
        }
      }
    }
    .coupon_table {
      margin-top: 20px;
      .el-table__body {
        .cell {
          span {
            cursor: pointer;
          }
        }
      }
    }
    .tab-page {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
    }
  }
  // 添加优惠券
  .addCoupon_drawer {
    .el-drawer__body {
      padding: 0 20px;
      overflow-y: auto;
      .el-form-item {
        .el-tag {
          font-size: 16px;
          height: 36px;
          line-height: 36px;
          cursor: pointer;
        }
      }
    }
  }
  // 添加商品
  .secondsKill_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .el-drawer__body {
      padding: 0 20px;
      overflow-y: auto;
    }
  }
  .fee-ul li:nth-child(1) .el-icon-circle-close {
    visibility: hidden;
  }
}
</style>
